@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.bg-xylo-purple {
  background-color: #2a2569;
}

.text-xylo-purple {
  color: #2a2569;
}

.hover\:bg-xylo-purple:hover {
  background-color: #2a2569;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #2d3748;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #2d3748;
}

*::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 20px;
  border: 3px solid #2d3748;
}
:root {
  --xylo-purple: #2a2569;
  --xylo-pink: #ED5C5E;
  --xylo-pink-light: #F58767;
  --dark-bg: #1a1a2e;
  --text-white: #ffffff;
  --text-gray-light: #e0e0e0;
  --text-gray: #a0a0a0;
  --alert-color: #f0a500;
}

.text-xylo-purple { color: var(--xylo-purple); }
.text-xylo-pink { color: var(--xylo-pink); }
.text-xylo-pink-light { color: var(--xylo-pink-light); }
.text-white { color: var(--text-white); }
.text-gray-light { color: var(--text-gray-light); }
.text-gray { color: var(--text-gray); }
.text-alert { color: var(--alert-color); }

.bg-xylo-purple { background-color: var(--xylo-purple); }
.bg-dark-bg { background-color: var(--dark-bg); }

/* Add any other color classes you need */